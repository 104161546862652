.contact-links {
    text-align: center;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    line-height: 2;
    background-color: #e35f13;
    border-radius: 200px;
    width: 300px;
    height: 300px;
    justify-content: center;
}

.contact-content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 100px 0;
    

    @media (min-width: 500px) {
        margin: 200px 100px;
    }
}