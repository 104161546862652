:root {
  --kiwi:	#a9c77e;
  --dark-brown: #563429;
}

@font-face {
  font-family: 'lemon';
  src: url(../fonts/Lemon-Regular.ttf) format('truetype');
}

a {
  text-decoration: none;
  color: var(--dark-brown);
}

.App {
  background-color: #e8dbbd;
  color: var(--dark-brown);
  min-height: 100vh;
  padding: 10px 20px;

  @media (min-width: 500px) {
    padding: 20px 60px;
  }
}

header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
  justify-content: space-between;
}

footer {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

h1 {
  font-family: 'lemon';
  font-size: 24px;

  @media (min-width: 500px) {
    font-size: 32px;
  }
}

nav {
  display: flex;
  justify-content: space-between;
  width: 120px;
  font-weight: bold;

  @media (min-width: 500px) {
    width: 180px;
  }
}
