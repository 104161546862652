.work-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 200px 0;
    text-align: center;

    @media (min-width: 500px) {
        padding: 300px 200px;
    }
}